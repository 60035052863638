.circular{
  padding-top: 10px !important;
  height:100px;
  width: 100px;
  position: relative;
  transform:scale(1);
}
.circular .inner{
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  margin: -30px 0 0 -40px;
  background: #dde6f0;
  border-radius: 100%;
}
.circular .dashboardProgressNumber{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -10%);
  z-index:10;
  font-size:16px;
  font-weight:500;
  color:#000;
}
.circular .bar{
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  -webkit-border-radius: 100%;
  clip: rect(0px, 100px, 100px, 50px);
}
.circle .bar .progress{
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 50px, 100px, 0px);
  background: #1EBD93;
}
.circle .left .progress{
  z-index:1;
  animation: left 2s linear both;
}
@keyframes left{
  100%{
    transform: rotate(180deg);
  }
}
.circle .right {
  transform: rotate(180deg);
  z-index:3;
 
}
.circle .right .progress{
  animation: right 2s linear both;
  animation-delay: 2s;
}
@keyframes right{
  100%{
    transform: rotate(180deg);
  }
}